<template>
  <div>
    <div class="title">
      <span></span>
      <span>设备位置监测</span>
      <span>{{ename}}</span>
    </div>
    <div class="cal">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in list" :key="item">

          <div class="header">
            <i class="el-icon-arrow-left"></i>
            <span>{{item.name}}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="content">
            <div class="one">
              <div :class="[item.parameter4 ? 'img':'imgnum']">{{item.parameter4? item.parameter4:'未知'}}</div>
              <div class="text">当前楼层</div>
            </div>
            <div class="two">
              <div :class="[item.parameter6 ==='未知'? 'imgnum':'img']">
                {{item.parameter6}}</div>
              <div class="text">当前经度</div>
            </div>
            <div class="two">
              <div :class="[item.parameter5==='未知'? 'imgnum':'img']">
                {{item.parameter5}}</div>
              <div class="text">当前纬度</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>
</template>

<script>
import { getLocatin } from '@/api/sterilizer'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      ename: 'Equipment position monitoring',
      list: []
    }
  },
  created () {
    this.ename = this.ename.toUpperCase()
    this.getallowance()
    this.timer = setInterval(() => {
      this.getallowance()

    }, 180000)
  },
  methods: {
    async getallowance () {
      let { data } = await getLocatin()
      console.log(data);
      this.list = data
      data.forEach(item => {
        item.parameter6 = item.parameter6 ? item.parameter6 : '未知'
        item.parameter5 = item.parameter5 ? item.parameter5 : '未知'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 30px;
  margin-bottom: 6px;
  margin-left: 5%;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header {
  display: flex;
  width: 566px;
  height: 36px;
  background: rgba(58, 242, 156, 0.29);
  border-radius: 5px;
  padding-top: 1%;
  margin-left: 7%;
  i {
    opacity: 0.3;
    border: 0px solid #ffffff;
    color: #fff;
    margin-top: 5px;
  }
  span {
    text-align: center;
    flex: 1;
    width: 54px;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
    letter-spacing: 2px;
  }
}
.content {
  width: 100%;
  background: rgba(0, 0, 0, 0);
  height: 200px;
  display: flex;
  padding-top: 12px;
  justify-content: space-evenly;
  .one {
    flex: 0.17;
    .img {
      width: 100%;
      background: url("../../../assets/img/切图4.0/编组 16.png") no-repeat;
      background-size: 100%;
      height: 150px;
      padding-top: 30px;
      text-align: center;
      font-size: 48px;
      font-family: DS-Digital-Bold, DS-Digital;
      font-weight: bold;
      color: #3af29c;
      line-height: 48px;
      text-shadow: 0px 0px 3px #3af29c;
    }
    .imgnum {
      width: 100%;
      background: url("../../../assets/img/切图4.0/编组 16.png") no-repeat;
      background-size: 100%;
      height: 150px;
      padding-top: 30px;
      text-align: center;
      font-size: 20px;
      font-family: DS-Digital-Bold, DS-Digital;
      font-weight: bold;
      color: #3af29c;
      line-height: 48px;
      text-shadow: 0px 0px 3px #3af29c;
    }
  }
  .two {
    flex: 0.17;
    .img {
      width: 100%;
      background: url("../../../assets/img/切图4.0/编组 16.png") no-repeat;
      background-size: 100%;
      height: 150px;
      padding-top: 30px;
      text-align: center;
      font-size: 48px;
      font-family: DS-Digital-Bold, DS-Digital;
      font-weight: 600;
      color: #3af29c;
      line-height: 48px;
      text-shadow: 0px 0px 3px #3af29c;
    }
    .imgnum {
      width: 100%;
      background: url("../../../assets/img/切图4.0/编组 16.png") no-repeat;
      background-size: 100%;
      height: 150px;
      padding-top: 30px;
      text-align: center;
      font-size: 20px;
      font-family: DS-Digital-Bold, DS-Digital;
      font-weight: 600;
      color: #3af29c;
      line-height: 48px;
      text-shadow: 0px 0px 3px #3af29c;
    }
  }
  .text {
    width: 100%;
    height: 30px;
    background: url("../../../assets/img/切图4.0/编组 17.png") no-repeat;
    background-size: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1dbd72;
    line-height: 22px;
    margin-top: -28px;
    line-height: 10px;
    margin-left: 2%;
  }
}
::v-deep .el-carousel {
  height: 220px;
  margin-left: -7%;
}
.cal {
  width: 640px;
  overflow: hidden;
  margin-left: 40px;
}
</style>