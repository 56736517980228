<template>
  <div class="all">
    <div class="title">
      <span></span>
      <span>机器人使用情况</span>
      <span>{{ename}}</span>
    </div>
    <el-carousel indicator-position="outside">
      <el-carousel-item v-for="item in list" :key="item">
        <div class="ca">
          <div class="uname">{{item.name}}</div>
          <div class="content">
            <div class="one">
              <span>{{item.parameter7===1? '运行中':item.parameter7? '未运行':'未知' }}</span>
              <div class="img"><span>运行状态</span> </div>
            </div>
            <div class="two">
              <span>{{item.parameter8? item.parameter8:0}}<span>%</span></span>
              <div class="img"><span>剩余电量</span> </div>
            </div>

          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

  </div>
</template>

<script>
import { getLocatin } from '@/api/sterilizer'
import clearTime from '@/mixin/clearTime.js'
export default {
  mixins: [clearTime],
  data () {
    return {
      ename: 'Robot usage',
      //数据
      list: {}
    }
  },
  created () {
    this.ename = this.ename.toUpperCase()
    this.getallowance()
    this.timer = setInterval(() => {
      this.getallowance()

    }, 180000)
  },
  methods: {
    async getallowance () {
      let { data } = await getLocatin()
      console.log(data);

      // console.log(data);


      this.list = data
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  padding-left: 5%;
  width: 90%;
}
.ca {
  display: flex;
  .uname {
    text-align: center;
    width: 14%;
    height: 119px;
    // vertical-align: middle;
    padding: 3%;
    padding-left: 4%;
    margin-top: 2%;
    background: url("../../../assets/img/切图4.0/编组 16 (2).png") no-repeat;
    background-size: 100%;
    margin-right: 1.5%;
    font-size: 20px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    writing-mode: vertical-lr;
    text-orientation: upright;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.title {
  margin-top: 10px;
  margin-bottom: 6px;

  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.content {
  width: 110%;
  display: flex;
  justify-content: space-evenly;
  background: url("../../../assets/img/切图4.0/编组 11.png") no-repeat;
  background-size: 90%;
  padding-right: 6%;
  height: 230px;
}
.one {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 50px;
  span:nth-child(1) {
    color: #ffc53b;
    line-height: 48px;
    margin-top: 20px;
    font-size: 24px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .img {
    background: url("~@/assets/img/切图4.0/编组 16备份.png") no-repeat;
    background-size: 100%;
    height: 130px;
    margin-top: -40px;
    span {
      line-height: 100px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      position: relative;
      top: 55px;
    }
  }
}
.two {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 50px;
  span:nth-child(1) {
    font-size: 48px;
    font-family: DS-Digital-BoldItalic, DS-Digital;
    font-weight: normal;
    color: #ffc53b;
    line-height: 48px;
    margin-top: 20px;
    margin-bottom: 5px;

    span {
      position: relative;
      bottom: 14%;
      left: 2%;
      font-size: 17px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffc53b;
    }
  }
  .img {
    background: url("~@/assets/img/切图4.0/编组 16备份.png") no-repeat;
    background-size: 100%;
    height: 130px;
    margin-top: -40px;
    span {
      line-height: 100px;
      font-size: 16px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      position: relative;
      top: 55px;
    }
  }
}

::v-deep .el-carousel {
  width: 110%;
}
</style>