<template>
  <div>
    <top :shouback="true" />
    <div class='container flex'>
      <div class="box_l flex1 h-max pt37 color-f">
        <!-- 设备类型 -->
        <deviceType :list1="list1" />
        <!-- 设备使用记录 -->
        <div class="title2">
          <span></span>
          <span>设备使用记录</span>
          <span>EQUIPMENT USE RECORD</span>
        </div>
        <div class="header2">
          <span></span>
          <span>设备名称</span>
          <span>开启时间</span>
          <span>关闭时间</span>
          <span></span>
        </div>
        <useprogress class="useprogress" :list="list8" :length="length2" :animation="animation2" />
        <!-- 每台累计消毒时长 -->
        <everyTime :list2="list2" />

        <!-- 实时保护情况 -->
        <div class="title">
          <span></span>
          <span>实时保护情况</span>
          <span>REAL TIME PROTECTION</span>
        </div>
        <div class="header">
          <span></span>
          <span>设备名称</span>
          <span>运行状态</span>
          <span>持续保护时长(h)</span>
          <span>保护率(%)</span>
          <span></span>
        </div>
        <realTime class="realTime" />
      </div>
      <div class="box_center w488 h-max pl20">
        <!-- 顶部条形图 -->
        <div class="titleImg"></div>
        <!-- 累计保护时长 -->
        <CountFlop :val='totalArea' title='累计保护时长' suffix='h' style="margin:0 auto" class="mt93 mr20" />
        <!-- 病毒消杀率 -->
        <div> 
          <Aerosol v-if="AerosolIsShow.indexOf(6)>-1" />
        <display v-else />
        </div>
        <!-- —— 近一周机器人使用记录 —— -->
        <robotUse />
      </div>
      <div class="box_r flex1 h-max pl70 ">
        <!-- 防疫机器人概览 -->
        <robotType />
        <!-- 设备位置监测 -->
        <sport />
        <!-- 近一周消毒时长 -->
        <div class="title1">
          <span></span>
          <span>近一周消毒时长</span>
          <span>DURATION OF DISINFECTION IN RECENT WEEK</span>
        </div>
        <div class="header1">
          <span></span>
          <span>设备名称：日期</span>
          <span>当日消毒时长</span>
          <span></span>
        </div>
        <kaijizhanbi class="kaijizhanbi" />
        <!-- 机器人使用情况 -->
        <todayTime />
      </div>
    </div>
  </div>

</template>

<script>
import top from '@/components/top.vue'
import deviceType from '@/views/Sterilizer/components/deviceType'
import useprogress from '@/views/Sterilizer/components/useprogress'
import everyTime from '@/views/Sterilizer/components/everyTime'
import realTime from '@/views/Whole/components/realTime.vue'
import display from '@/views/Sterilizer/components/display'
import robotType from '@/views/SterilizerRobot/components/robotType'
import sport from '@/views/SterilizerRobot/components/sport'
import kaijizhanbi from '@/views/SterilizerRobot/components/kaijizhanbi.vue'
import todayTime from '@/views/SterilizerRobot/components/todayTime.vue'
import robotUse from '@/views/SterilizerRobot/components/robott.vue'
import Aerosol from '@/views/SterilizerRobot/components/Aerosol'
import { formatDate } from '@/filters'
import CountFlop from '@/components/CountFlop'
import { gettotalTime, getoverview, getSingelTime, getDeviceUse } from '@/api/sterilizer'
export default {
  components: { top, deviceType, useprogress, everyTime,Aerosol, realTime, display, robotType, sport, kaijizhanbi, todayTime, robotUse, CountFlop },
  data () {
    return {
      //累计消毒面积
      totalArea: '',
      list1: [],
      list2: [],
      list8: [],
      //数据的长度
      length2: null,
      //动画的停止与否
      animation2: '',
      AerosolIsShow:JSON.parse(sessionStorage.getItem('lookType')),//是否显示气溶胶页面
    };
  },
  created () {
    this.gettotalTime()
    //获取设备概览
    this.getoverview()
    //获取每台累计消毒时长
    this.getSingelTime()
    this.getDeviceUse()
  },
  mounted () { },
  methods: {
    async getDeviceUse () {
      let { data } = await getDeviceUse()
      // console.log(data);
      let arr = []
      data.forEach(item => {
        item = {
          ...item,
          startTime: item.startTime ? formatDate(item.startTime * 1000) : '--:--:--',
          finishTime: item.finishTime ? formatDate(item.finishTime * 1000) : '--:--:--',
          workingTime: item.workingTime ? parseInt(item.workingTime / 3600) : 0
        }
        arr.push(item)
      })
      if (data.length < 5) {
        this.list8 = arr
        this.length2 = this.list8.length
        this.animation2 = 'paused'
      } else {
        this.list8 = [...arr, ...arr]
        this.length2 = this.list8.length
        this.animation2 = 'running'
      }
    },
    //获取累计保护时长
    async gettotalTime () {
      const { data } = await gettotalTime()
      let num = parseInt(data.greenNum / 3600)
      this.toOrderNum(num)
    },
    //转换千分位
    toOrderNum (num) {
      num = num.toString()
      if (num.length < 7) {
        num = '0' + num
        this.toOrderNum(num)
      } else {
        //加逗号
        num = num.slice(1, 4) + ',' + num.slice(4, 7)
        // this.count = num.split('') 
        this.totalArea = num
      }
    },
    //获取设备概览
    async getoverview () {

      let { data } = await getoverview()
      this.list1 = data
    },
    //获取每台累计消毒时长
    async getSingelTime () {
      this.list = []
      let { data } = await getSingelTime()
      this.list2 = data
      this.reloading()
    },
    reloading () {
      setTimeout(() => {
        //获取累计保护时长
        this.gettotalTime()
        //获取设备概览
        this.getoverview()
        //获取每台累计消毒时长
        this.getSingelTime()
        this.getDeviceUse()
      }, 180000)
    }
  }
}

</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 1004px;
  background: #000;
  perspective: 1800px;
  .box_l {
    padding-left: 2%;
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
    background-size: 100% 100%;
    max-width: 720px;
  }
  .box_r {
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
    background-size: 100% 100%;
    max-width: 720px;
  }
}
.title2 {
  margin-bottom: 6px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header2 {
  display: flex;
  width: 590px;
  height: 36px;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.1%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    position: relative;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4) {
    flex: 1;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 34px;
    vertical-align: bottom;
  }
  span:nth-child(2) {
    padding-left: 22px;
  }
  span:nth-child(3) {
    text-align: start;
  }
  span:nth-child(4) {
    text-align: center;
  }
  span:nth-child(5) {
    display: inline-block;
    margin-top: 1.2%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
}
.useprogress {
  margin-left: -7%;
  height: 150px;
  overflow: hidden;
}
.title {
  margin-bottom: 6px;
  margin-top: 20px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header {
  display: flex;
  padding-left: 4%;

  width: 85%;
  height: 36px;
  display: flex;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -1.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    position: relative;
    left: -4%;
    text-align: center;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4),
  span:nth-child(5) {
    text-align: center;

    flex: 1;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 35px;
    vertical-align: bottom;
  }
  span:nth-child(2) {
    flex: 1.3;
  }
  span:nth-child(3) {
    flex: 1.7;
  }
  span:nth-child(4) {
    flex: 3;
  }
  span:nth-child(5) {
    flex: 2;
  }

  span:nth-child(6) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
}
.realTime {
  height: 150px;
  overflow: hidden;
}
.title1 {
  margin-top: 10px;
  margin-bottom: 6px;
  margin-left: 6%;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.header1 {
  margin-left: 5%;
  display: flex;
  width: 569px;
  height: 36px;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    display: inline-block;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 34px;
    vertical-align: bottom;
  }
  span:nth-child(2) {
    flex: 1.2;

    margin-left: 3%;
  }
  span:nth-child(3) {
    margin-left: 54%;
    flex: 1;
  }
  span:nth-child(4) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    margin-left: 3.5%;
  }
}
.kaijizhanbi {
  height: 150px;
  overflow: hidden;
}
.titleImg {
  width: 330px;
  height: 12px;
  background: url("../../assets/img/切图4.0/头部配件.png") no-repeat;
  background-size: 100%;
  position: relative;
  top: 50px;
  left: 14%;
}
</style>