<template>
  <div class="allm">
    <div class="titlem">
      <span></span>
      <span>每台累计消毒时长</span>
      <span>{{ename}}</span>
    </div>
    <el-carousel indicator-position="outside" height="90px">
      <el-carousel-item v-for="(item,index) in list2" :key="index">
        <div class="content">

          <div class="one" v-for="(key,i) in item" :key="i">
            <span>{{parseInt(key?.useTiem/3600)}}<span>h</span><br />
              <span>{{key?.deviceName}}</span></span>
            <div class="img"></div>
          </div>

        </div>

      </el-carousel-item>
    </el-carousel>

  </div>
</template>

<script>
export default {
  props: {
    list2: Array
  },
  data () {
    return {
      ename: 'Accumulated disinfection duration'.toUpperCase(),
    }
  },
  
}
</script>

<style lang="scss" scoped>
.allm {
  overflow: hidden;
  width: 83%;
  .titlem {
    margin-top: 25px;
    margin-bottom: 45px;
    width: 100%;
    span:nth-child(1) {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 2px solid #08f4da;
    }
    span:nth-child(2) {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin-right: 8px;
      margin-left: 4px;
    }
    span:nth-child(3) {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #08f4da;
      line-height: 22px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    height: 160px;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0);
  }
  ::v-deep .el-carousel {
    height: 160px;
  }
  ::v-deep .el-carousel__button {
    display: none;
  }
  ::v-deep .el-carousel__container {
    height: 130px !important;
  }
  .one {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 290px;

    .img {
      background: url("~@/assets/img/切图4.0.1/编组 4备份 3.png") no-repeat;
      background-size: 100%;
      height: 160px;
      position: relative;
      bottom: 50px;
    }
    span {
      display: inline-block;
      margin-bottom: -10px;
    }
    span:nth-child(1) {
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;
      color: #9365f6;
      line-height: 48px;
      span:nth-child(1) {
        font-size: 17px;
        font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
        font-weight: normal;
        color: #9365f6;
        position: relative;
        bottom: 10%;
      }
      span:nth-child(3) {
        display: inline-block;
        width: 114px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 20px;
      }
    }
  }
}
.titlem {
  margin-top: 59px;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  // span:nth-child(2) {
  //   font-size: 16px;
  //   font-family: PingFangSC-Medium, PingFang SC;
  //   font-weight: 500;
  //   color: #ffffff;
  //   line-height: 22px;
  //   margin-right: 8px;
  //   margin-left: 4px;
  // }
  // span:nth-child(3) {
  //   font-size: 16px;
  //   font-family: PingFangSC-Semibold, PingFang SC;
  //   font-weight: 600;
  //   color: #08f4da;
  //   line-height: 22px;
  // }
}
</style>