<template>
  <div>
    <div class="all">
      <div class="content" :style="{'--uploadStyle':length+'s','--animation':animation}">
        <div v-for="item in list" :key="item">
          <span>{{item.name}}</span> <span>: {{item.time}}</span>
          <el-progress stroke-width="6" :percentage="item.percentage">
            <span>{{item.pro}} h</span>
          </el-progress>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import { getRunningHours } from '@/api/sterilizer'
export default {
  data () {
    return {
      //数据
      list: [],
      //数据的长度
      length: null,
      //动画的停止与否
      animation: true
    }
  },
  created () {

    this.getjinarea()

  },
  methods: {
    async getjinarea () {
      let { data } = await getRunningHours()
      let allTime = 0
      data.map(item => {
        allTime += +item.format
      })
      data.forEach(item => {
        item.time = item.time.substr(5).split('/').join('-')
        item.percentage = parseInt(item.format * 100 / allTime)
        item.pro = item.format ? (item.format / 3600).toFixed(1) : 0
      })

      if (data.length < 4) {
        this.list = data
        this.length = this.list.length
        this.animation = 'paused'
      } else {
        this.list = [...data, ...data]
        this.length = this.list.length
        this.animation = 'running'
      }
      this.reload()

    },
    reload () {
      setTimeout(() => {
        this.getjinarea()
      }, 180000)
    },

  }
}

</script>

<style lang="scss" scoped>
.all {
  margin-left: 3%;
}
.content:hover {
  animation-play-state: paused;
}
.content {
  animation: move var(--uploadStyle) linear infinite;
  animation-play-state: var(--animation);
  width: 90%;
  background: rgba(0, 0, 0, 0);
  padding-left: 27%;
  span {
    position: relative;
    top: 24px;
    left: -33%;
    width: 129px;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 22px;
    display: inline-block;
    width: 114px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 18%;
    vertical-align: middle;
  }
  ::v-deep .el-progress {
    span:nth-child(1) {
      text-align: center;
      display: inline-block;
      width: 100px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
      position: relative;
      top: -2px;
    }
  }
  ::v-deep .el-progress-bar__inner {
    background: linear-gradient(270deg, #9565f7 0%, #0757ba 100%);
  }
  ::v-deep .el-progress-bar__outer {
    background: rgba(255, 255, 255, 0.2);
    margin-right: 10%;
    margin-left: 10%;
  }
  ::v-deep .el-progress__text {
    margin-right: -3%;
  }
}
/* 通过CSS3动画滚动marquee */
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
</style>