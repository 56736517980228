<template>
  <div class="all">
    <div class="title"></div>
    <div class="header">—— 近一周机器人使用记录 ——</div>
    <div class="header1">
      <span></span>
      <span>设备名称</span>
      <span>开始运行时间</span>
      <span>结束运行时间</span>
      <span>消毒时长</span>
      <span></span>
    </div>
    <div class="content">
      <table :style="{'--uploadStyle':length+'s','--animation':animation}">
        <tr v-for="item in list" :key="item">
          <td>{{item.name}}</td>
          <td>{{item.start}}</td>
          <td>{{item.finish}}</td>
          <td>{{item.workingHours}}h</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script >
import { getWeekUser } from '@/api/sterilizer'
export default {
  data () {
    return {
      //数据
      list: [],
      length: null, //数据长度
      animation: true,//控制动画的停止与否
    }
  },
  created () {
    this.getdeviceUse()
  
  },
  methods: {
    // 处理时间
    gettime (timestamp, gettype) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      if (gettype == 'yyddmm') {
        var Y = date.getFullYear() + '/';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ' + ' ';
        var h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      }
      // 算时间差
      if (gettype == 'getcha') {
        // 两个时间戳相差的秒数
        var usedTime = timestamp * 1000
        // console.log(usedTime, 'usedTime')
        var hours = Math.floor(usedTime / (3600 * 1000))
        var leave1 = usedTime % (3600 * 1000);
        var minutes = Math.floor(leave1 / (60 * 1000))
        if (hours <= 0) { hours = minutes + '分钟' }
        else { hours = hours + '小时' }
      }
      return {
        yymmdd: Y + M + D + h + m + s,
        timecha: hours
      };
    },
    async getdeviceUse () {
      let { data } = await getWeekUser()
      data.forEach(item => {
        item.start = item.startTime ? this.gettime(item.startTime / 1000, 'yyddmm').yymmdd : item.start
        item.finish = item.finishTime ? this.gettime(item.finishTime / 1000, 'yyddmm').yymmdd : item.finish
        item.workingHours = (item.workingTime / 3600).toFixed(1)
      })
      // console.log(data);
      if (data.length > 3) {
        this.list = [...data, ...data]
        this.length = this.list.length
        this.animation = 'running'

      } else {
        this.list = data
        this.length = this.list.length
        this.animation = 'paused'
      }
      this.reload()

    },
    reload () {
      setTimeout(() => {
        this.getdeviceUse()
      }, 180000)
    }
  }
}
</script>

<style lang="scss" scoped>
.all {
  box-sizing: border-box;
  position: relative;
  top: -2%;
}
.title {
  width: 135%;
  background: url("../../../assets/img/切图4.0/底部框.png") no-repeat;
  background-size: 100%;
  height: 200px;
  position: relative;
  bottom: 180px;
  right: 19%;
}
.header {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
  position: relative;
  bottom: 365px;
  text-align: start;
  background: rgba(0, 0, 0, 0);
  padding-left: 23%;
}
.header1 {
  position: relative;
  bottom: 360px;
  left: -19%;
  display: flex;
  width: 630px;
  height: 36px;
  text-align: center;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.4) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  span:nth-child(1) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4),
  span:nth-child(5) {
    display: inline-block;
    height: 22px;
    font-size: 16px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #ffffff;
    line-height: 34px;
    vertical-align: bottom;
  }
  span:nth-child(2),
  span:nth-child(5) {
    flex: 1;
  }
  span:nth-child(3),
  span:nth-child(4) {
    flex: 2;
  }
  span:nth-child(6) {
    display: inline-block;
    margin-top: 1.2%;
    margin-left: -0.5%;
    vertical-align: middle;
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
    margin-left: 1%;
  }
}

.content {
  width: 137%;
  height: 130px;
  overflow: hidden;
  padding: 0 5%;
  background: rgba(0, 0, 0, 0);
  position: relative;
  bottom: 360px;
  right: 20%;
  table {
    width: 100%;
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    animation: move var(--uploadStyle) linear infinite;
    animation-play-state: var(--animation);
    tr {
      height: 42px;
      display: flex;
      td {
        // margin-top: 32px;
        font-size: 16px;
        font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
        font-weight: normal;
        color: #ffffff;
        line-height: 35px;
        text-align: center;
      }
      td:nth-child(1) {
        text-align: center;
        display: inline-block;
        width: 114px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
      td:nth-child(2),
      td:nth-child(3) {
        flex: 2;
      }
      td:nth-child(4),
      td:nth-child(5) {
        flex: 1;
      }
    }
  }
  table:hover {
    animation-play-state: paused;
  }
}
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
</style>