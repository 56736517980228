<template>
  <div>
    <div class="title">
      <span></span>
      <span>防疫机器人概览</span>
      <span>{{ename}}</span>
    </div>
    <div class="content">
      <div class="one">
        <span>{{list?.greenNum}}</span>
        <span></span>
        <span>(台)<br />
          设备总数</span>
      </div>

      <div class="one">
        <span>{{list.redNum? list.redNum:0}}</span>
        <span></span>
        <span>(台)<br />

          故障设备数</span>
      </div>
      <div class="one">
        <span>{{allTime? allTime:0}}</span>
        <span></span>
        <span>(H)<br />
          设备消毒时长</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getoverview, getWorkingHours } from '@/api/sterilizer'

export default {
  data () {
    return {
      ename: 'Overview of epidemic prevention robots',
      //机器人
      list: {},
      //总消毒时间
      allTime: null,
      //消毒总面积
      area: null
    }
  },
  created () {
    this.ename = this.ename.toUpperCase()
    this.getoverview()
    this.getHours()
  
  },
  methods: {
    //获取机器人总数
    async getoverview () {
      let { data } = await getoverview()
      this.list = data[3]

    },
    //获取消毒总时间
    async getHours () {
      let { data } = await getWorkingHours()
      this.allTime = (data.area / 3600).toFixed(1)
    },

    reload () {
      setTimeout(() => {
        this.getoverview()
        this.getHours()
        this.getAreas()
      }, 180000)
    }
  }

}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 30px;
  margin-bottom: 35px;
  margin-left: 5%;
  span:nth-child(1) {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #08f4da;
  }
  span:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    margin-right: 8px;
    margin-left: 4px;
  }
  span:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
    line-height: 22px;
  }
}
.content {
  display: flex;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  justify-content: space-evenly;
  .one {
    width: 169px;
    background: url("../../../assets/img/切图4.0/概览边框.png") no-repeat;
    background-size: 100%;
    height: 148px;
    display: flex;
    text-align: center;
    flex-direction: column;
    span:nth-child(1) {
      font-size: 48px;
      font-family: DS-Digital-BoldItalic, DS-Digital;
      font-weight: normal;
      color: #c6fe37;
      line-height: 48px;
      text-shadow: 0px 0px 4px #a4ca44;
      margin-top: 14px;
      margin-bottom: 10px;
    }
    span:nth-child(2) {
      display: inline-block;
      width: 130px;
      height: 0px;
      background: #fff;
      margin-left: 12%;
      opacity: 0.2;
      border: 1px solid #ffffff;
    }
    span:nth-child(3) {
      margin-top: 8px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 28px;
    }
  }
}
</style>